import * as React from "react";

const CenteredHeading = (props) => {
  let testimonialIcon;
  if (props.includeEmoji) {
    testimonialIcon = (
      <img
        className="inline"
        src={"/heart-emoji.svg"}
        height={50}
        width={50}
        alt="Blushing heart emoji"
      />
    );
  }
  return (
    <div className="grid border-b border-gray-300">
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <img
        className="opacity-30 h-48 lg:h-72"
        style={{ gridArea: "1/1", objectFit: `cover`, width: `100%` }}
        src={"/hero-bg.svg"}
        alt=""
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-purple-600 tracking-wide uppercase">
              {props.pageTitle}
            </h2>
            <span className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              {props.mainText} {testimonialIcon}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenteredHeading;
