import * as React from "react";

const TestimonialWithImage = () => {
  return (
    <div className="bg-purple-800">
      <div className="py-8 flex flex-col-reverse md:flex-row items-center justify-center">
        <p className="text-4xl">⭐⭐⭐⭐⭐</p>
        <blockquote className="max-w-xl mx-12 mb-4 md:mb-0">
          <div>
            <svg
              className="h-12 w-12 text-white opacity-25"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p className="mt-6 text-2xl font-medium text-white">
              Kevin helped me build my new Shopify site - I love how fast it is!
              He was super helpful and happy to answer any questions I had. Will
              definitely be working together again for any future projects!
            </p>
          </div>
          <footer className="mt-6">
            <p className="text-base font-medium text-white">Jason M</p>
            <p className="text-base font-medium text-indigo-100">
              Entrepreneur
            </p>
          </footer>
        </blockquote>
      </div>
    </div>
  );
};

export default TestimonialWithImage;
