import * as React from "react";
import Testimonial from "../components/Testimonial";
import CenteredHeading from "../components/CenteredHeading";
import TestimonialWithImage from "../components/TestimonialWithImage";
import Seo from "../components/Seo";

const TestimonialsPage = () => {
  return (
    <>
      <Seo title="Testimonials" />
      <CenteredHeading
        pageTitle="Testimonials"
        mainText="You're making us blush"
        includeEmoji
      />
      <TestimonialWithImage />
      <Testimonial
        quoteText="We needed help ASAP to meet accessibility requirements for all of our existing sites. EnvizionLabs fixed every issue we had and now we are fully compliant. Thank you!"
        author="Michael S"
        job="Human Resources"
      />
      <Testimonial
        quoteText="From the start of the project, through to completion, Kevin supported us and exceeded our expectations in every way."
        author="Amy"
        job="Blogger"
        svgRight
      />
    </>
  );
};

export default TestimonialsPage;
